<template>
  <b-row>
    <b-col cols="12">
      <b-card-code
        title=""
        no-body
      >
        <b-card-body>
          <b-form @submit.prevent="submit">
            <!--  Name -->
            <b-form-group>
              <label for="email">Name:</label>
              <b-form-input
                id="name"
                v-model="facility.name"
                type="text"
                placeholder="Name"
              />
            </b-form-group>
            <!--  City -->
            <b-form-group>
              <label for="city">City:</label>
              <b-form-input
                id="city"
                v-model="facility.city"
                type="text"
                placeholder="City"
                autocomplete="off"
              />
            </b-form-group>
            <!--  Address -->
            <b-form-group>
              <label for="address">Address:</label>
              <b-form-input
                id="address"
                v-model="facility.address"
                type="text"
                placeholder="Address"
                autocomplete="off"
              />
            </b-form-group>
            <!--  State -->
            <b-form-group>
              <label for="state">State:</label>
              <v-select
                id="mc-role"
                v-model="facility.state"
                label="state"
                :options="states"
                :reduce="state => state.state"
                placeholder="State"
                class="w-100"
                @input="changeState"
              >
                <template
                  #option="{ state, abbreviated_state }"
                  :index="abbreviated_state"
                >
                  {{ state }}
                </template>
              </v-select>
            </b-form-group>
            <!--  State -->
            <b-form-group>
              <label for="abbreviated_state">Abbreviated state:</label>
              <v-select
                id="mc-role"
                v-model="facility.abbreviated_state"
                label="abbreviated_state"
                :options="states"
                :reduce="state => state.abbreviated_state"
                placeholder="Abbreviated state"
                class="w-100"
                @input="changeAbbreviatedState"
              >
                <template
                  #option="{ abbreviated_state }"
                  :index="abbreviated_state"
                >
                  {{ abbreviated_state }}
                </template>
              </v-select>
            </b-form-group>
            <!--  Zip Code -->
            <b-form-group>
              <label for="zipcode">Zip Code:</label>
              <b-form-input
                id="zipcode"
                v-model="facility.zipcode"
                type="text"
                placeholder="Zip Code"
                autocomplete="off"
              />
            </b-form-group>

            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
            >
              Submit
            </b-button>
          </b-form>
        </b-card-body>
      </b-card-code>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCardBody, BForm, BFormInput, BFormGroup, BButton,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import * as facilityService from '@/services/facility'
import states from '@/utils/state.json'

export default {
  components: {
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    BCardBody,
    BCardCode,
    BButton,
  },

  data() {
    return {
      states,
      facility: {
        name: '',
        city: '',
        address: '',
        state: null,
        abbreviated_state: null,
        zipcode: '',
      },
    }
  },

  methods: {
    changeState(e) {
      const selectedItem = this.states.find(state => state.state === e)
      this.facility.abbreviated_state = selectedItem.abbreviated_state
    },

    changeAbbreviatedState(e) {
      const selectedItem = this.states.find(state => state.abbreviated_state === e)
      this.facility.state = selectedItem.state
    },

    submit() {
      facilityService.create({
        ...this.facility,
        company_id: this.$store.state.auth.companyId,
      })
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.$nextTick(() => {
            this.$router.push({ name: 'company-facility' })
          })
        })
        .catch(() => {
          this.$nextTick(() => {
          })
        })
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
